<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">
        a) The hybridization of
        <stemble-latex content="$\ce{Xe}$" />
        in
        <stemble-latex content="$\ce{XeOF4}$" /> is:
      </p>

      <v-radio-group
        v-model="inputs.multipleChoiceAnswer"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2 mt-2">b) I think this because:</p>

      <p class="mb-n6">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'CalPolyPracticeExam3Q6',
  components: {
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        explainedResponse: null,
      },
      options: [
        {text: `$\\ce{sp^{3}}$`, value: 'sp3'},
        {text: `$\\ce{sp^{3}d}$`, value: 'sp3d'},
        {text: `$\\ce{sp^{3}d^{2}}$`, value: 'sp3d2'},
        {text: `$\\ce{p^{2}d^{2}}$`, value: 'p2d2'},
      ],
    };
  },
};
</script>
